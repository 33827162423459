$(document).on('turbolinks:load', function() {
  $(".search_summit").keyup(function () {
    $.ajax({
      url: "/summits/",
      type: 'GET',
      dataType: 'script',
      data: {search: $(this).val()}
    });
  });

  $("body").on('change', '.company_partner_filter', function(e){
    list = $("input[name='company_partner_id[]']:checked").map(function () {
      return this.value;
    }).get();
    $.ajax({
      url: `/summits/${$(e.target).data("summitId")}/filter_sessions`,
      type: 'GET',
      dataType: 'script',
      data: {company_partners: list}
    });
  })

  $("body").on('change', '.category_filter', function(e){
    list = $("input[name='category_id[]']:checked").map(function () {
      return this.value;
    }).get();
    $.ajax({
      url: `/summits/${$(e.target).data("summitId")}/filter_sessions`,
      type: 'GET',
      dataType: 'script',
      data: {category_ids: list}
    });
  })

  // Add validation to prevent form submission if the location is not selected from the autocomplete dropdown.
  $(document).on('click', "#save_summit_details", function(event){
    var inputValue   = $('[name="summit[location]"]').val();
    var initialValue = $('[name="summit[location]"]').attr('data-initial-value');
    var found = false;
    $('[name="summit[location]"]').autoComplete("node").siblings(".dropdown-menu").find(".dropdown-item").each(function() {
      if ($(this).text() === inputValue) {
        found = true;
      }
    });
    if (!found && inputValue !== initialValue) {
      event.preventDefault();
      $('[name="summit[location]"]').val('');
      $('[name="summit[location]"]').focus();
    }
  });

  $('body').on('click', '#add_panelist', () => {
    $('.panelist_modal').removeClass('d-none')
    $('#session_section').addClass('d-none')
    $('#timetableModal').animate({ scrollTop: 0 }, 'fast');
  });

  $('body').on('click', '#edit_panelist', function() {
    $('#session_section').addClass('d-none');
    var panelist = $(this).attr("data-panelist");
    $('.panelist_modal').removeClass('d-none');
    if ($('#panelist_' + panelist)){
      $('#panelist_' + panelist).removeClass('d-none');
      $('#panelist_').addClass('d-none');
    }
    $('#timetableModal').animate({ scrollTop: 0 }, 'fast');
  });

  $(document).on('click', '#save_summit_day_btn', function() {
    if ($('#summit_summit_time').val()){
      var dateRange = $('#summit_summit_time').val().split(' - ');
      var checkDate = $('[name="timetable[day]"]').val();
    }

    var sponsor = $('#partner_id').val();

    if(checkDate){
      var startDate = new Date(dateRange[0]);
      var endDate   = new Date(dateRange[1]);
      var checkDate = new Date(checkDate);
      if (checkDate >= startDate && checkDate <= endDate) {
        return;
      }else{
        event.preventDefault();
        $('#summit_day_date_error').text('Date should be in between ' + dateRange + ' summit date range.');
        $('#summit_day_date_error').removeClass('hidden');
      }
    }
    if (typeof sponsor !== 'undefined' && sponsor.length === 0){
      $('#timetable_session_sponsors_error').text('Please select at least one sponsor.');
      $('#timetable_session_sponsors_error').removeClass('hidden');
    }
  });

  $('body').on('click', '#add_session', () => {
    $('[id^="panelist_"]').addClass('d-none');
    $('.panelist_modal').addClass('d-none')
    $('#session_section').removeClass('d-none')
  });

  $('body').on('change', "#session_time_from", function(e) {
    var timetable_day = $('#timetable_day').val();
    var time_from     = $('#session_time_from').val();

    var formattedDateTime = timetable_day + " " + time_from;
    $('#timetable_session_time_from').val('');
    $('#timetable_session_time_from').val(formattedDateTime);
  });

  $('body').on('change', "#session_time_to", function(e) {
    var timetable_day = $('#timetable_day').val();
    var time_to       = $('#session_time_to').val();

    var formattedDateTime = timetable_day + " " + time_to;
    $('#timetable_session_time_to').val('');
    $('#timetable_session_time_to').val(formattedDateTime);
  });

  // travel and emergency info form search
  $(() => {
    $('#TravelAndEmergencyInfoformSearh').keyup(e => {
      let $this = $(e.currentTarget)
      let authenticity_token = $('meta[name=csrf-token]').attr('content')
      let search = $this.val()

      $('#travel-and-emergency-info-here').load('filter_emergency_contact_and_travel_information', { authenticity_token, search })
    })
  })
});
