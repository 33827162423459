$(document).on('turbolinks:load', function () {
  var data = {
    name: '#privacy_name',
    email: '#privacy_email',
    photo: '#privacy_photo',
    library: '#privacy_library',
    description: '#privacy_description',
    education: '#privacy_education',
  };

  var privacy_value = $('#library_privacy').val();
  if (privacy_value === 'friends') {
    $('#privacy_friends_list').removeClass('d-none');
  }

  function clickEventForRadiaBtn(data, key){
    const element = data[key];
    const buttonSelector = `a.only_me_link.${key}`;
    $(element).on('click', 'input[type="radio"]' , function() {
      let btn_img_src = $(this).siblings().find('img.hidden').attr('src');
      let btn_text = $(this).siblings().find('.audience-item__title').text();
      $(`${buttonSelector} img`).attr('src', btn_img_src);
      $(`${buttonSelector} img`).attr('height','30');
      $(`${buttonSelector} img`).attr('width','30');
      $(`${buttonSelector} span`).text(btn_text);
      $(`${buttonSelector} input[type=hidden]`).val($(this).val());
      if (key === 'library') {
        var privacy_value = $(this).val();
        const ajaxUrl = '/users/update_library_privacy';
        $.ajax({
          url: ajaxUrl,
          type: 'POST',
          data: { library_privacy: privacy_value, },
          success: function () {
            if (privacy_value === 'friends') {
                $('#privacy_friends_list').removeClass('d-none');
            } else {
                $('#privacy_friends_list').addClass('d-none');
            }
          }
        });
      }
    });
  }

  for (var key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      clickEventForRadiaBtn(data, key);
    }
  }

  $(document).on('click', '.setting_form_tab', (e)=>{
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById($(e.currentTarget).data("type")).style.display = "block";
    e.currentTarget.className += " active";
  });
});
